import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';

@Component({
  selector: 'app-numeric-slider-config',
  templateUrl: './numeric-slider-config.component.html',
  styleUrls: ['./numeric-slider-config.component.scss'],
})
export class NumericSliderConfigComponent implements OnInit {

  @Input() el: OHNElement;
  @Input() lang: any;

  minText: string = 'minText';
  maxText: string = 'maxText';

  constructor() { }

  ngOnInit() {
    console.log("Current lang", this.lang);
    if (this.lang && this.lang.locale) {
      this.minText = 'minText_' + this.lang.locale;
      this.maxText = 'maxText_' + this.lang.locale;
    }
  }

}
