import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OHNElement } from '../../models/ohn-instances';

@Component({
  selector: 'app-basic-elements-config-renderer',
  templateUrl: './basic-elements-config-renderer.component.html',
  styleUrls: ['./basic-elements-config-renderer.component.scss'],
})
export class BasicElementsConfigRendererComponent implements OnInit {

	@Input() elements : OHNElement[];
  @Input() lang : any;
	@Output() deleteElementEmitter = new EventEmitter();

  constructor() { }

  ngOnInit() {
    console.log("lang", this.lang);
  }

  reorderElements(ev) {
    this.elements = ev.detail.complete(this.elements);
  }

  deleteElement(elementIndex: number) {
  	this.deleteElementEmitter.emit(elementIndex);
  }

}
