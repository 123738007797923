import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController, PopoverController } from '@ionic/angular';
import { OHNUser, OHNEventSequence, OHNEventSequenceItem, OHNCalendarEvent, OHNMedication, OHNEventPreset, OHNElement, OHNCalendarAction, OHNCalendarActionList, OHNCalendarSequence, OHNUserRole } from '../../models/ohn-instances';
import { OhnApiService } from '../../services/ohn-api.service';
import { CalendarEventSchedulerComponent } from '../../components/calendar-event-scheduler/calendar-event-scheduler.component';
import { OhnService } from '../../services/ohn.service';
import { RuleEditorComponent } from '../../components/rule-editor/rule-editor.component';
import { OhnLanguageService } from '../../services/ohn-language.service';
import * as _ from 'underscore/underscore';

import { DEBUG_MODE, IS_RAD_ONC_APP, INSTACART_ENABLED } from '../../../environments/environment';

@Component({
  selector: 'app-calendar-sequence-manager',
  templateUrl: './calendar-sequence-manager.page.html',
  styleUrls: ['./calendar-sequence-manager.page.scss'],
})
export class CalendarSequenceManagerPage implements OnInit {

	//@Input() calendarElement : OHNElement;

	editingCalendarAction : OHNCalendarActionList;

	calendarActions : OHNCalendarActionList[] = [];

	calendarSequence : OHNCalendarSequence;

  currentPresetActionIndex : number;

	calendarSequenceList : OHNCalendarSequence[] = [];

	numberOfWeeks : number = 1;

	isCloning : boolean = false;

	isNewAction : boolean = false;

	isNewSequence : boolean = false;

	loading: boolean = false;

  ///////////////////////////////////////////////////////////////

  surveyList : OHNElement;

  workoutList : OHNElement;

  medicationList : OHNEventPreset[];

  meetingList : OHNEventPreset[];

  textMessageList : OHNEventPreset[];

  instacartList : OHNEventPreset[];

  sequenceList : OHNEventSequence[];

  educationList: OHNElement;

  currentSurvey : OHNElement;

  currentSequence : OHNEventSequence;

  currentWorkout : OHNElement;

  currentPage : OHNElement;

  currentMedication : OHNEventPreset;

  currentMeeting : OHNEventPreset;

  currentMessage : OHNEventPreset;

  currentInstance : OHNEventPreset;

  currentLibrary: OHNElement;

  currentLibraryPage: OHNElement;

  currentLibrarySectionIdx: number;
  currentLibraryBookIdx: number;
  currentLibraryPageIdx: number;

  eventTypeSegment : string = 'survey';

  segmentManage: string = 'actions';

  roles: OHNUserRole[] = [];

  currentRole : OHNUserRole;

  currentProfile : OHNElement;

  profiles : any = {};

  app : OHNElement;

  locales : any[] = [];

  currentLanguage : any;

  IS_RAD_ONC_APP = IS_RAD_ONC_APP;

  INSTACART_ENABLED = INSTACART_ENABLED;

  healthKitDataTypes : any[] = [
    {
      slug : 'weight',
      text : 'Weight',
      enabled : false
    },
    {
      slug : 'blood_pressure',
      text : 'Blood Pressure',
      enabled : false
    },
    {
      slug : 'heart_rate',
      text : 'Heart Rate',
      enabled : false
    },
    {
      slug : 'steps',
      text : 'Steps',
      enabled : false
    },
    {
      slug : 'distance',
      text : 'Distance',
      enabled : false
    },
    {
      slug : 'calories',
      text : 'Calories',
      enabled : false
    },
    {
      slug : 'height',
      text : 'Height',
      enabled : false
    },
    {
      slug : 'activity',
      text : 'Activity',
      enabled : false
    },
    {
      slug : 'fat_percentage',
      text : 'Fat Percentage',
      enabled : false
    }
  ];

  constructor(
  	private modalController : ModalController,
  	private ohnApi: OhnApiService,
  	private ohnService: OhnService,
  	private toastController: ToastController,
  	public popoverController: PopoverController,
    private lS: OhnLanguageService,
  ){ }

  ngOnInit() {
    this.locales = this.lS.getAvailableLocales();
    if (this.locales.length > 0) {
      this.currentLanguage = this.locales.find(l=>{return l.locale == 'en'});
    } else {
      this.currentLanguage = {name : 'English', locale : 'en'}
    }
    this.initContent();
    this.loadRoles();
    this.loadApp();
  }

  initContent() {
    this.loadSurveyList();
    this.loadWorkoutList();
    this.loadMedicationList();
    this.loadMeetingList();
    this.loadSequenceList();
    this.loadEducationList();
    if (IS_RAD_ONC_APP) {
      this.loadTextMessageList();  
    }
    if (INSTACART_ENABLED) {
      this.loadInstacartList();
    }
  }

  languageChanged() {
    this.eventTypeSegment  = 'survey';
    this.segmentManage = 'actions';
    this.currentSurvey = undefined;

    this.currentSequence = undefined;

    this.currentWorkout = undefined;

    this.currentPage = undefined;

    this.currentMedication = undefined;

    this.currentMeeting = undefined;

    this.currentMessage = undefined;

    this.currentInstance = undefined;

    this.currentLibrary = undefined;
    this.currentLibraryPage = undefined;

    this.initContent()
  }

  loadApp() {
    this.ohnApi.getElement('app', 0).subscribe(app => {
      this.app = <OHNElement>app;
      this.app.config.healthKit.fields.forEach(f=>{
        this.healthKitDataTypes.find(dt=>{
          return dt.slug == f;
        }).enabled = true;
      });
    });
  }

  updateDataTypes() {
    this.app.config.healthKit.fields = this.healthKitDataTypes.filter(dt=>{return dt.enabled;}).map(dt=>{return dt.slug;});
  }

  saveHealthKitSettings() {
    this.ohnApi.setElement('app', this.app).subscribe(app => {
    });
    this.notifyOfSaved('Sync Settings Updated');
  }

  loadRoles(){
    this.ohnApi.getAvailableRoles().subscribe(roleList => {
      this.roles = roleList;
      this.roles.forEach(r=>{
        this.loadProfile(r);
      })
    });
  }

  selectRole(role: OHNUserRole){
    this.currentProfile = this.profiles[role.name];
  }

  loadProfile(role : OHNUserRole) {
    this.ohnApi.getElement(this.ohnService.appRoleModifier(role.name, false)+'_profile' , 4).subscribe(profile => {
      this.profiles[role.name] = profile;
    });
  }

  loadSurveyList(){
    this.loading = true;
    this.ohnApi.getElementCustomLocale('surveys_container', 4, this.currentLanguage.locale).subscribe(surveyList => {
      this.surveyList = surveyList;
      DEBUG_MODE && console.log("Survey List", this.surveyList);
      this.loading = false;
    });
  }

  loadMedicationList(){
    this.ohnApi.getElementCustomLocale('medications_container', 1, this.currentLanguage.locale).subscribe(medicationContainer => {
      this.medicationList = (medicationContainer.config && medicationContainer.config.medicationList) ? JSON.parse(medicationContainer.config.medicationList) : [];
      DEBUG_MODE && console.log('Medication List',this.medicationList);
    });
  }

  loadMeetingList(){
    this.ohnApi.getElementCustomLocale('meetings_container', 1, this.currentLanguage.locale).subscribe(meetingContainer => {
      this.meetingList = (meetingContainer.config && meetingContainer.config.meetingList) ? JSON.parse(meetingContainer.config.meetingList) : [];
    });
  }

  loadInstacartList(){
    this.ohnApi.getElementCustomLocale('instacart_instance_container', 1, this.currentLanguage.locale).subscribe(instacartInstanceContainer => {
      this.instacartList = (instacartInstanceContainer.config && instacartInstanceContainer.config.instanceList) ? JSON.parse(instacartInstanceContainer.config.instanceList) : [];
    });
  }

  loadTextMessageList(){
    this.ohnApi.getElementCustomLocale('text_messages_container', 1, this.currentLanguage.locale).subscribe(textMessageContainer => {
      this.textMessageList = (textMessageContainer.config && textMessageContainer.config.messageList) ? JSON.parse(textMessageContainer.config.messageList) : [];
    });
  }

  loadSequenceList(){
    this.ohnApi.getElementCustomLocale('sequences_container', 1, this.currentLanguage.locale).subscribe(sequenceContainer => {
      this.sequenceList = (sequenceContainer.config && sequenceContainer.config.sequencesList) ? JSON.parse(sequenceContainer.config.sequencesList) : [];
      DEBUG_MODE && console.log('Sequence List', this.sequenceList);
    });
  }

  loadWorkoutList(){
    this.ohnApi.getElementCustomLocale('workouts_container', 4, this.currentLanguage.locale).subscribe(workoutList => {
      this.workoutList = workoutList;
    });
  }
  
  loadEducationList() {
    this.loading = true;
    DEBUG_MODE && console.log("currentLanguage", this.currentLanguage.locale);
    this.ohnApi.getElementCustomLocale('education_container', 5, this.currentLanguage.locale).subscribe(educationList => {
        this.educationList = educationList;
        this.educationList.elements.map(lib => { return lib.hidden = true; });
        for (let i = 0; i < this.educationList.elements.length; i++) {
            this.educationList.elements[i].elements.map(lib => { return lib.hidden = true; });
            for (let j = 0; j < this.educationList.elements[i].elements.length; j++) {
                this.educationList.elements[i].elements[j].elements.map(lib => { return lib.hidden = true; });
            }
        }
        DEBUG_MODE && console.log("this.educationList", this.educationList);
    });
  }

  selectSurvey(index: number) {
    this.currentSurvey = this.surveyList.elements[index];
    (!this.currentSurvey.config) && (this.currentSurvey['config'] = {});
    DEBUG_MODE && console.log("Current Survey", this.currentSurvey);
    this.currentPage = this.currentSurvey.elements[0];
  }

  selectWorkout(index: number) {
    this.currentWorkout = this.workoutList.elements[index];
    this.currentPage = this.currentWorkout.elements[0];
  }

  selectMedication(index: number) {
    this.currentMedication = this.medicationList[index];
  }

  selectSequence(index: number) {
    this.currentSequence = this.sequenceList[index];
  }

  selectMeeting(index: number) {
    this.currentMeeting = this.meetingList[index];
  }

  selectMessage(index: number) {
    this.currentMessage = this.textMessageList[index];
  }

  selectInstance(index: number) {
    this.currentInstance = this.instacartList[index];
  }

  selectLibrary(index) {
    this.currentLibrary = this.educationList.elements[index];
    this.currentLibraryPage = undefined;
    
    DEBUG_MODE && console.log("this.currentLibrary", this.currentLibrary);
    DEBUG_MODE && console.log("this.currentPage", this.currentPage);
  }

  addSurvey() {
    this.surveyList.elements.push(
      <OHNElement>{
        text : 'New Survey',
        _cls : 'Page',
        controller : 'branchedContentController',
        elements : []
      }
    );
    this.currentSurvey = this.surveyList.elements[this.surveyList.elements.length - 1];
    this.currentPage = this.currentSurvey.elements[0];
  }

  addWorkout() {
    this.workoutList.elements.push(
      <OHNElement>{
        text : 'New Workout',
        _cls : 'Page',
        controller : 'branchedContentController',
        elements : [],
        config: {
          isFitbitWorkout : false
        }
      }
    );
    this.currentWorkout = this.workoutList.elements[this.workoutList.elements.length - 1];
    this.currentPage = this.currentWorkout.elements[0];
  }

  addMedication() {
    this.medicationList.push(
      <OHNEventPreset>{
        title : 'New Medication',
        description : '',
        id : OhnService.getUniqueId(this.medicationList),
        contentObject : {
          dose : ''
        }
      }
    );
    this.currentMedication = this.medicationList[this.medicationList.length - 1];
  }

  addSequence() {
    this.sequenceList.push(
      <OHNEventSequence>{
        title : 'New Sequence',
        description : '',
        days : {},
        numberOfWeeks : 1,
        id : OhnService.getUniqueId(this.sequenceList)
      }
    );
    this.currentSequence = this.sequenceList[this.sequenceList.length - 1];
  }

  addMeeting() {
    this.meetingList.push(
      <OHNEventPreset>{
        title : 'New Meeting',
        description : '',
        id : OhnService.getUniqueId(this.meetingList),
        contentObject : {
          dose : ''
        }
      }
    );
    this.currentMeeting = this.meetingList[this.meetingList.length - 1];
  }

  addMessage() {
    this.textMessageList.push(
      <OHNEventPreset>{
        title : 'New Text Message',
        description : '',
        id : OhnService.getUniqueId(this.textMessageList),
        inner_element_slug : ''
      }
    );
    this.currentMessage = this.textMessageList[this.textMessageList.length - 1];
  }

  addInstance() {
    this.instacartList.push(
      <OHNEventPreset>{
        title : 'New Instacart Instance',
        text : 'Go To Instacart',
        id : OhnService.getUniqueId(this.instacartList),
        image_url : ''
      }
    );
    this.currentInstance = this.instacartList[this.instacartList.length - 1];
  }

  addPage() {
    this.currentSurvey.elements.push(
      <OHNElement>{
        text : 'Survey Page',
        _cls : 'Page',
        controller : 'pageController',
        elements : []
      }
    );
    this.currentPage = this.currentSurvey.elements[this.currentSurvey.elements.length - 1];
  }

  addWorkoutPage() {
    this.currentWorkout.elements.push(
      <OHNElement>{
        text : 'Workout Step',
        _cls : 'Page',
        controller : 'pageController',
        elements : []
      }
    );
    this.currentPage = this.currentWorkout.elements[this.currentWorkout.elements.length - 1];
  }
  
  addLibrary() {
    this.educationList.elements.push(
      <OHNElement>{
        text: 'New Library',
        _cls: 'Page',
        controller: 'pageController',
        hidden: false,
        elements: []
      }
    );
    
    DEBUG_MODE && console.log("this.educationList", this.educationList);
    this.currentLibrary = this.educationList.elements[this.educationList.elements.length - 1];
    this.currentLibraryPage = undefined;
  }

  addLibrarySection() {
    this.currentLibrary.elements.push(
      <OHNElement>{
        text: 'New Section',
        _cls: 'Page',
        controller: 'pageController',
        hidden: false,
        elements: []
      }
    );
  }

  addLibraryBook(sectionIndex) {
    this.currentLibrary.elements[sectionIndex].elements.push(
      <OHNElement>{
        text: 'New Book',
        _cls: 'Page',
        controller: 'branchedContentController',
        hidden: false,
        elements: []
      }
    );
  }
  
  addLibraryPage(sectionIndex, bookIndex) {
    this.currentLibrary.elements[sectionIndex].elements[bookIndex].elements.push(
      <OHNElement>{
        text: 'New Book Page',
        _cls: 'Page',
        controller: 'pageController',
        elements: []
      }
    );
  }

  addElement(element: OHNElement) {
    this.currentPage.elements.push(element);
  }

  addElementToProfile(element: OHNElement) {
    this.currentProfile.elements.push(element);
  }

  addLibraryPageElement(element: OHNElement) {
    this.currentLibraryPage.elements.push(element);
  }

  deleteFromSequence(eventIndex: number, day: number) {
    this.currentSequence.days[day].splice(eventIndex, 1);
  }

  deleteElement(elementIndex: number) {
    this.currentPage.elements.splice(elementIndex, 1);
  }

  deleteLibraryPageElement(elementIndex) {
    this.currentLibraryPage.elements.splice(elementIndex, 1);
  }

  deleteLibrarySection(sectionIndex) {
    this.currentLibrary.elements.splice(sectionIndex, 1);
    this.currentLibraryPage = undefined;
  }

  deleteLibraryBook(sectionIndex, bookIndex) {
    this.currentLibrary.elements[sectionIndex].elements.splice(bookIndex, 1);
    this.currentLibraryPage = undefined;
  }

  deleteElementFromProfile(elementIndex: number) {
    this.currentProfile.elements.splice(elementIndex, 1);
  }

  saveProfile(){
    this.loading = true;
    if (this.currentProfile) {
      this.ohnApi.setElement(this.currentProfile.element_slug, this.currentProfile).subscribe(profile => {
        this.loading = false;
      });
    }
  }

  deleteCurrentSurvey() {
    this.surveyList.elements = this.surveyList.elements.filter((e)=>{ return e !== this.currentSurvey});
    this.currentSurvey = this.surveyList.elements[0];
    this.ohnApi.setElement('surveys_container', this.surveyList).subscribe(surveyList => {
      this.notifyOfSaved('Survey Deleted');
    });
  }

  deleteCurrentWorkout() {
    this.workoutList.elements = this.workoutList.elements.filter((e)=>{ return e !== this.currentWorkout});
    this.currentWorkout = this.workoutList.elements[0];
    this.ohnApi.setElement('workouts_container', this.workoutList).subscribe(workoutList => {
      this.notifyOfSaved('Workout Deleted');
    });
  }

  deleteCurrentMedication() {
    this.medicationList = this.medicationList.filter((e)=>{ return e !== this.currentMedication});
    this.currentMedication = this.medicationList[0];
    this.ohnApi.setElement('medications_container', {config : {medicationList : JSON.stringify(this.medicationList)}}).subscribe(medicationContainer => {
      this.notifyOfSaved('Medication Deleted');
    });
  }

  deleteCurrentMeeting() {
    this.meetingList = this.meetingList.filter((e)=>{ return e !== this.currentMeeting});
    this.currentMeeting = this.meetingList[0];
    this.ohnApi.setElement('meetings_container', {config : {meetingList : JSON.stringify(this.meetingList)}}).subscribe(meetingContainer => {
      this.notifyOfSaved('Meeting Deleted');
    });
  }

  deleteCurrentMessage() {
    this.textMessageList = this.textMessageList.filter((e)=>{ return e !== this.currentMessage });
    this.currentMessage = this.textMessageList[0];
    this.ohnApi.setElement('text_messages_container', {config : {messageList : JSON.stringify(this.textMessageList)}}).subscribe(messageContainer => {
      this.notifyOfSaved('Text Message Deleted');
    });
  }

  deleteCurrentInstance() {
    this.instacartList = this.instacartList.filter((e)=>{ return e !== this.currentInstance });
    this.currentInstance = this.instacartList[0];
    this.ohnApi.setElement('instacart_instance_container', {config : {instanceList : JSON.stringify(this.instacartList)}}).subscribe(instanceContainer => {
      this.notifyOfSaved('Instacart Instance Deleted');
    });
  }

  deleteCurrentLibrary() {
    this.educationList.elements = this.educationList.elements.filter((e) => { return e !== this.currentLibrary; });
    this.currentLibrary = this.educationList.elements[0];
    this.ohnApi.setElement('education_container', this.educationList).subscribe(educationList => {
        this.notifyOfSaved('Library Deleted');
    });
  }

  deleteCurrentSequence() {
    this.sequenceList = this.sequenceList.filter((e)=>{ return e !== this.currentSequence});
    this.currentSequence = this.sequenceList[0];
    this.ohnApi.setElement('sequences_container', {config : {sequencesList : JSON.stringify(this.sequenceList)}}).subscribe(sequenceContainer => {
      this.notifyOfSaved('Sequence Deleted');
    });
  }

  deleteCurrentPage() {
    this.currentSurvey.elements = this.currentSurvey.elements.filter((e)=>{ return e !== this.currentPage});
    this.currentPage = this.currentSurvey.elements[0];
  }
  
  deleteCurrentLibraryPage() {
    this.currentLibrary.elements[this.currentLibrarySectionIdx].elements[this.currentLibraryBookIdx].elements = this.currentLibrary.elements[this.currentLibrarySectionIdx].elements[this.currentLibraryBookIdx].elements.filter((e) => { return e !== this.currentLibraryPage; });
      this.currentLibraryPage = this.currentLibrary.elements[this.currentLibrarySectionIdx].elements[this.currentLibraryBookIdx].elements[0];
  }

  deleteCurrentStep() {
    this.currentWorkout.elements = this.currentWorkout.elements.filter((e)=>{ return e !== this.currentPage});
    this.currentPage = this.currentWorkout.elements[0];
  }

  selectPage(page: OHNElement) {
    this.currentPage = page;
  }
  
  selectLibraryPage(sectionIdx, bookIdx, pageIdx) {
    this.currentLibrarySectionIdx = sectionIdx;
    this.currentLibraryBookIdx = bookIdx;
    this.currentLibraryPageIdx = pageIdx;
    this.currentLibraryPage = this.currentLibrary.elements[this.currentLibrarySectionIdx].elements[this.currentLibraryBookIdx].elements[this.currentLibraryPageIdx];
    
    DEBUG_MODE && console.log("selectLibraryPage", this.currentLibraryPage);
  }

  saveSurveyList(){
    this.loading = true;
    if (this.currentSurvey.element_slug) {
      this.ohnApi.setElementCustomLocale(this.currentSurvey.element_slug, this.currentSurvey, this.currentLanguage.locale).subscribe(survey => {
        this.updateContainerElement(this.currentSurvey, false);
      });
    } else {
      this.ohnApi.setElementCustomLocale('surveys_container', this.surveyList, this.currentLanguage.locale).subscribe(surveyList => {
        this.currentSurvey.element_slug = surveyList.elements[surveyList.elements.length - 1].element_slug;
        this.updateContainerElement(this.currentSurvey, true);
      });
    }
  }

  saveWorkoutList() {
    this.loading = true;
    if (this.currentWorkout.element_slug) {
      this.ohnApi.setElementCustomLocale(this.currentWorkout.element_slug, this.currentWorkout, this.currentLanguage.locale).subscribe(workout => {
        this.updateContainerElement(this.currentWorkout, false);
      });
    } else {
      this.ohnApi.setElementCustomLocale('workouts_container', this.workoutList, this.currentLanguage.locale).subscribe(workoutList => {
        this.currentWorkout.element_slug = workoutList.elements[workoutList.elements.length - 1].element_slug;
        this.updateContainerElement(this.currentWorkout, true);
      });
    }
  }

  saveMedicationList() {
    this.loading = true;
    this.ohnApi.setElementCustomLocale('medications_container', {config : {medicationList : JSON.stringify(this.medicationList)}}, this.currentLanguage.locale).subscribe(medicationContainer => {
      this.loading = false;
    });
  }

  saveMeetingList() {
    this.loading = true;
    this.ohnApi.setElementCustomLocale('meetings_container', {config : {meetingList : JSON.stringify(this.meetingList)}}, this.currentLanguage.locale).subscribe(meetingContainer => {
      this.loading = false;
    });
  }

  saveMessageList() {
    this.loading = true;
    this.ohnApi.setElementCustomLocale('text_messages_container', {config : {messageList : JSON.stringify(this.textMessageList)}}, this.currentLanguage.locale).subscribe(messageContainer => {
      this.loading = false;
    });
  }

  saveInstanceList() {
    this.loading = true;
    this.ohnApi.setElementCustomLocale('instacart_instance_container', {config : {instanceList : JSON.stringify(this.instacartList)}}, this.currentLanguage.locale).subscribe(instanceContainer => {
      this.loading = false;
    });
  }

  saveEducationList() {
    this.loading = true;
    if (this.currentLibrary.element_slug) {
      this.ohnApi.setElementCustomLocale(this.currentLibrary.element_slug, this.currentLibrary, this.currentLanguage.locale).subscribe(library => {
        this.updateContainerElement(this.currentLibrary, false);
      });
    }
    else {
      this.ohnApi.setElementCustomLocale('education_container', this.educationList, this.currentLanguage.locale).subscribe(educationList => {
        this.currentLibrary.element_slug = educationList.elements[educationList.elements.length - 1].element_slug;
        this.updateContainerElement(this.currentLibrary, true);
      });
    }
  }

  saveSequenceList() {
    this.loading = true;
    this.ohnApi.setElementCustomLocale('sequences_container', {config : {sequencesList : JSON.stringify(this.sequenceList)}}, this.currentLanguage.locale).subscribe(sequenceContainer => {
      this.loading = false;
    });
  }

  //deeper element pull to get slugs
  updateContainerElement(element: OHNElement, needsReportCreation: boolean) {
    this.ohnApi.getElement(element.element_slug, 3).subscribe(responseElement => {
      this.loading = false;
      this.notifyOfSaved('Updated');
      element = <OHNElement>responseElement;
      if (needsReportCreation) {
        element.report_element = <OHNElement>{
          text : 'Report for ' + element.text,
          element_slug : 'report' + element.text.replace(/ |\#|\&|\%|\?|\@|\/|\\/ig, '_').toLowerCase() + new Date().getTime(),
          elements : this.collectBasicElements(element),
          _cls : 'ReportElement',
          controller : 'reportElementController'
        }
      } else {
        element.report_element.elements = this.collectBasicElements(element)
      }
      this.ohnApi.setElement(element.element_slug, {report_element : element.report_element}).subscribe();
    });
  }

  collectBasicElements(element: OHNElement) {
    let basicElements : OHNElement[] = [];
    element.elements.forEach(p => {
      p.elements.forEach(e => {
        if (['stringFieldController', 'numericFieldController', 'dateTimeFieldController', 'pickOneDefaultController', 'pickManyDefaultController', 'yesNoController', 'speedSensorElementController', 'noiseLevelElementController', 'heartRateElementController', 'bloodPressureElementController', 'fitbitHeartRateElementController', 'timerElementController', 'numericPickOneController', 'periodToDaysController', 'randomizationElementController', 'textMessageController', 'hiddenStringController', 'currentTimestampPickerController', 'numericSliderFieldController'].indexOf(e.controller) >= 0) {
          basicElements.push(e);
        }
      });
    });
    return basicElements;
  }

  async showSchedulerModal(week: number, day : number) {
    const modal = await this.modalController.create({
      component: CalendarEventSchedulerComponent,
      componentProps: {
        workoutList : this.workoutList,
        surveyList : this.surveyList,
        medicationList : this.medicationList,
        meetingList : this.meetingList,
        textMessageList : this.textMessageList,
        instacartList : this.instacartList,
        viewType : 'sequence',
        selectedDate : new Date()
      },
      backdropDismiss: true,
      mode: "md"
    });

    modal.onDidDismiss().then((res) => {
      if (res.data) {
        if (!this.currentSequence.days[week*7 + day]) this.currentSequence.days[week*7 + day] = [];
        this.currentSequence.days[week*7 + day].push(<OHNEventSequenceItem>{
          title : (['workout', 'survey'].indexOf(res.data.type) >= 0) ? res.data.element.text : res.data.element.title,
          elementId : (['workout', 'survey'].indexOf(res.data.type) >= 0) ? res.data.element.element_slug : res.data.element.id,
          type : res.data.type
        });
      }
    });

    return await modal.present();
  }

  async notifyOfSaved(text: string) {
    const toast = await this.toastController.create({
      message: text,
      duration: 2000
    });
    toast.present();
  }

  newWeek() {
    this.currentSequence.numberOfWeeks++;
  }

  reorderPages(event) {
    this.currentSurvey.elements = event.detail.complete(this.currentSurvey.elements);
  }

  reorderLibrarySections(event) {
    this.currentLibrary.elements = event.detail.complete(this.currentLibrary.elements);
  }
  reorderLibraryBooks(event, sectionIndex) {
    this.currentLibrary.elements[sectionIndex].elements = event.detail.complete(this.currentLibrary.elements[sectionIndex].elements);
  }
  reorderLibraryPages(event, sectionIndex, bookIndex) {
    this.currentLibrary.elements[sectionIndex].elements[bookIndex].elements = event.detail.complete(this.currentLibrary.elements[sectionIndex].elements[bookIndex].elements);
  }
  toggleLibraryContent(el) {
      el.hidden = !el.hidden;
  }

  async openRuleEditor() {
    const modal = await this.modalController.create({
      component: RuleEditorComponent,
      componentProps: {
        parentElement : this.currentSurvey
      },
      backdropDismiss: true,
      mode: "md"
    });

    modal.onDidDismiss().then((res) => {
      if (res.data) {
      
      }
    });

    return await modal.present();
  }
}